export default [
  { code: '77000000000', city: 'Москва', region: '' },
  { code: '78000000000', city: 'Санкт-Петербург', region: '' },
  { code: '29000001000', city: 'Архангельск', region: 'Архангельская область' },
  { code: '25000001000', city: 'Владивосток', region: 'Приморский край' },
  { code: '34000001000', city: 'Волгоград', region: 'Волгоградская область' },
  { code: '36000001000', city: 'Воронеж', region: 'Воронежская область' },
  { code: '66000001000', city: 'Екатеринбург', region: 'Свердловская область' },
  { code: '18000001000', city: 'Ижевск', region: 'Республика Удмуртская' },
  { code: '38000003000', city: 'Иркутск', region: 'Иркутская область' },
  { code: '16000001000', city: 'Казань', region: 'Республика Татарстан' },
  {
    code: '42000009000',
    city: 'Кемерово',
    region: 'Кемеровская область - Кузбасс область',
  },
  { code: '23000001000', city: 'Краснодар', region: 'Краснодарский край' },
  { code: '24000001000', city: 'Красноярск', region: 'Красноярский край' },
  { code: '51000001000', city: 'Мурманск', region: 'Мурманская область' },
  {
    code: '16000002000',
    city: 'Набережные Челны',
    region: 'Республика Татарстан',
  },
  {
    code: '52000001000',
    city: 'Нижний Новгород',
    region: 'Нижегородская область',
  },
  { code: '54000001000', city: 'Новосибирск', region: 'Новосибирская область' },
  { code: '55000001000', city: 'Омск', region: 'Омская область' },
  { code: '59000001000', city: 'Пермь', region: 'Пермский край' },
  { code: '61000001000', city: 'Ростов-на-Дону', region: 'Ростовская область' },
  { code: '64000001000', city: 'Саратов', region: 'Саратовская область' },
  { code: '63000001000', city: 'Самара', region: 'Самарская область' },
  { code: '23000007000', city: 'Сочи', region: 'Краснодарский край' },
  {
    code: '86000010000',
    city: 'Сургут',
    region: 'Ханты-Мансийский Автономный округ - Югра автономная область',
  },
  { code: '69000001000', city: 'Тверь', region: 'Тверская область' },
  { code: '63000007000', city: 'Тольятти', region: 'Самарская область' },
  { code: '71000001000', city: 'Тула', region: 'Тульская область' },
  { code: '72000001000', city: 'Тюмень', region: 'Тюменская область' },
  { code: '73000001000', city: 'Ульяновск', region: 'Ульяновская область' },
  { code: '02000001000', city: 'Уфа', region: 'Республика Башкортостан' },
  { code: '27000001000', city: 'Хабаровск', region: 'Хабаровский край' },
  { code: '74000001000', city: 'Челябинск', region: 'Челябинская область' },
  { code: '76000001000', city: 'Ярославль', region: 'Ярославская область' },
]
