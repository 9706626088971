






import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Location } from '~/model'

@Component
export default class DialogCitiesListItem extends Vue {
  @Prop({ type: Object })
  item!: Location
}
