import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,_vm._g(_vm._b({attrs:{"max-width":"780","content-class":"cities-list-content","fullscreen":!_vm.$vuetify.breakpoint.smAndUp},on:{"click:outside":function($event){_vm.search = ''}},model:{value:(_vm.isShowDialog),callback:function ($$v) {_vm.isShowDialog=$$v},expression:"isShowDialog"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c(VCard,{staticClass:"cities-list-card"},[_c(VCardTitle,[_c('div',{staticClass:"cities-list-title"},[_vm._v("Выбор города")]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c('div',{staticClass:"cities-list-close",on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("ft-remove")])],1)],1),_vm._v(" "),_c(VCardText,{staticClass:"mt-1"},[_c('div',{staticClass:"cities-list-subtitle"},[_vm._v("Всего 1 279 городов")]),_vm._v(" "),_c('div',{staticClass:"cities-list-input my-5"},[_c('text-field',{attrs:{"append-icon":_vm.searchIcon,"placeholder":"Поиск города"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('div',{staticClass:"cities-list-block"},[(!_vm.search && _vm.currentArea.code)?_c('div',{staticClass:"cities-list-selected mt-1 mb-6"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.currentArea.city))]),_vm._v(" "),_c(VIcon,{staticClass:"pl-2"},[_vm._v("ft-check")])],1)]):_vm._e(),_vm._v(" "),(_vm.search)?_c('ul',{staticClass:"cities-column"},[(_vm.areaSearchResult.length)?_vm._l((_vm.areaSearchResult),function(item){return _c('city-list-item',{key:item.id,attrs:{"item":item},on:{"set-location":_vm.setLocation}})}):[_c('li',[_c('a',[_vm._v("Ничего не найдено")])])]],2):_c('ul',{staticClass:"cities-column"},_vm._l((_vm.cities),function(item){return _c('city-list-item',{key:item.id,attrs:{"item":item},on:{"set-location":_vm.setLocation}})}),1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }