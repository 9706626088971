
































import { Component, Vue, PropSync } from 'nuxt-property-decorator'
import { Location } from '~/model'

@Component
export default class DialogConfirmGeoIp extends Vue {
  @PropSync('value')
  isShowDialog!: boolean

  get area(): Location {
    return this.$store.state.anket.form.area?.city ?? ''
  }

  selectCities(e: Event): void {
    e.stopPropagation()
    this.$emit('show-cities-dialog')
  }
}
