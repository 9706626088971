


































































import { Component, Vue, PropSync, Watch } from 'nuxt-property-decorator'
import CityListItem from './DialogCitiesListItem.vue'
import TextField from '~/components/Elements/TextField/TextField.vue'
import { Location } from '~/model'
import Cities from '~/constants/Cities'
import { Debounce } from '~/utils/Decorators'

@Component({
  components: { TextField, CityListItem },
})
export default class DialogCitiesList extends Vue {
  @PropSync('value')
  isShowDialog!: boolean

  @PropSync('area')
  innerArea!: Location

  search: string = ''

  isSearching: boolean = false

  areaSearchResult: Location[] = []

  get isAreaSearching(): boolean {
    return this.areaSearchResult.length > 0 && !!this.search
  }

  get searchIcon(): string {
    return this.isSearching ? 'ft-refresh-cw icon-spinner' : 'ft-search'
  }

  get cities(): Location[] {
    return Cities.filter(
      (city: Location) => city.code !== this.currentArea.code
    )
  }

  get currentArea(): Location {
    return this.$store.getters['anket/form'].area
  }

  setLocation(area: Location): void {
    this.innerArea = area
    this.closeDialog()
  }

  closeDialog(): void {
    this.isShowDialog = false
    this.search = ''
  }

  @Watch('search')
  @Debounce(500)
  async onSearchChanged(query: string): Promise<void> {
    if (!query) return

    try {
      this.isSearching = true
      this.areaSearchResult = (
        await this.$store.dispatch('anket/areaSearch', query)
      ).results
    } catch (e) {
      console.error(e)
    } finally {
      this.isSearching = false
    }
  }
}
