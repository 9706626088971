




























import { Component, Watch, mixins } from 'nuxt-property-decorator'
import inRange from 'lodash/inRange'
import CurrencyFieldMixin from '~/mixins/CurrencyFieldMixin'

type TypeRangeForStep = { min: number; max: number; step: number }

const rangeForStep: TypeRangeForStep[] = [
  { min: 0, max: 200000, step: 10000 },
  { min: 200000, max: 1000000, step: 50000 },
  { min: 1000000, max: 5000001, step: 100000 },
]

const MIN = 1000

@Component({
  inheritAttrs: false,
})
export default class TextFieldRange extends mixins(CurrencyFieldMixin) {
  innerRangeValue: number = 0
  el: HTMLInputElement | null = null
  step: number = 1000

  @Watch('innerValue', { immediate: true })
  onValueChanged(val: number): void {
    this.innerRangeValue = val
    this.setVal(this.innerRangeValue)
  }

  onInputChange(val: number): void {
    this.innerValue = val
  }

  onRangeChange(): void {
    const rangeVal = this.el?.valueAsNumber || 0

    const targetRange: TypeRangeForStep =
      rangeForStep.find((range: TypeRangeForStep) =>
        inRange(rangeVal, range.min, range.max)
      ) || rangeForStep[0]

    this.step = targetRange.step

    if (rangeVal <= MIN) {
      this.innerValue = MIN
    } else {
      this.innerValue = rangeVal
    }

    this.setVal(this.innerValue)
  }

  setVal(val: number): void {
    if (!this.el) return
    this.el.style.setProperty('--value', String(val))
  }

  mounted(): void {
    this.el = document.querySelector(
      'input[type="range"].currency-text-field-range-input'
    )

    if (!this.el) return

    this.el.style.setProperty('--value', this.el.value)
    this.el.style.setProperty('--min', this.el.min === '' ? '0' : this.el?.min)
    this.el.style.setProperty(
      '--max',
      this.el.max === '' ? '100' : this.el?.max
    )
  }
}
