




import { Component, Vue, Watch, Prop } from 'nuxt-property-decorator'

type digit = string | number

@Component
export default class AnimatedNumber extends Vue {
  @Prop({ type: [Number, String], default: 0 })
  number!: digit

  @Prop({ type: Number, default: 10 })
  devider!: number

  displayNumber: number = 0
  interval: number = 0

  mounted() {
    this.displayNumber = this.convertNumber(this.number)
  }

  convertNumber(num: digit) {
    return Number(String(num).replace(/\D/g, ''))
  }

  @Watch('number')
  onNumberChanged(val: digit): void {
    clearInterval(this.interval)

    const num = this.convertNumber(val)

    if (num === this.displayNumber) {
      return
    }

    this.interval = window.setInterval(() => {
      if (this.displayNumber !== num) {
        let change = (num - this.displayNumber) / this.devider

        change = change >= 0 ? Math.ceil(change) : Math.floor(change)

        this.displayNumber = this.displayNumber + change
      }
    }, this.devider)
  }
}
