

















































































import {
  Component,
  Watch,
  mixins,
  Ref,
  Vue,
  PropSync,
} from 'nuxt-property-decorator'

import DialogConfirmGeoIp from './dialogs/DialogConfirmGeoIp.vue'
import DialogCitiesList from './dialogs/DialogCitiesList/DialogCitiesList.vue'

import { eventBus } from '~/eventBus'
import TextFieldRange from '~/components/Elements/TextField/TextFieldRange.vue'
import TextField from '~/components/Elements/TextField/TextField.vue'
import SelectField from '~/components/Elements/Select/Select.vue'
import Autocomplete from '~/components/Elements/Autocomplete/Autocomplete.vue'
import AnimatedNumber from '~/components/Elements/AnimatedNumber/AnimatedNumber.vue'

import {
  PeriodRateType,
  AddressSearch,
  Anket,
  Location,
  PreparedSearchResult,
} from '~/model'
import { PeriodRates, Routes } from '~/constants'
import { LoadingMixin } from '~/mixins'
import { Debounce } from '~/utils/Decorators'
import Rules from '~/utils/Rules'

@Component({
  components: {
    AnimatedNumber,
    Autocomplete,
    DialogConfirmGeoIp,
    DialogCitiesList,
    SelectField,
    TextField,
    TextFieldRange,
  },
})
export default class SearchForm extends mixins(LoadingMixin) {
  @Ref() readonly ref!: any

  @PropSync('form')
  innerForm!: Anket

  searchPeriodRateItems: PeriodRateType[] = PeriodRates
  isShowConfirmGeoIpDialog: boolean = false
  isShowCitiesListDialog: boolean = false
  rules: typeof Rules = Rules

  address: AddressSearch = {
    search: '',
    result: [],
    isLoading: false,
  }

  get area(): Location {
    return this.$store.state.anket.form.area
  }

  get result(): PreparedSearchResult {
    return this.$store.getters['anket/searchResult']
  }

  submitForm(): void {
    const isValid = this.ref.validate()

    if (!isValid) {
      return
    }

    try {
      this.isLoading = true

      if (this.$auth.loggedIn) {
        this.$router.push(Routes.FORM)
      } else {
        this.$router.push(Routes.AUTH)
      }
    } catch (e) {
      this.$store.dispatch('common/showSnackbar', {
        text: 'При сохранении анкетных данных произошла ошибка: попробуйте чуть позже',
        color: 'error',
      })
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  @Watch('innerForm.loan_amount', { immediate: true })
  @Watch('innerForm.loan_term', { immediate: true })
  @Debounce(500)
  async onRateGuide(val: unknown): Promise<void> {
    if (!val) return

    try {
      await this.$store.dispatch('anket/rateGuide')
    } catch (e) {
      this.$store.dispatch('common/showSnackbar', {
        text: 'При расчете произошла ошибка: попробуйте позже',
        color: 'error',
      })
    }
  }

  @Watch('innerForm.area', { deep: true })
  onAreaChanged(area: Location): void {
    Vue.set(this.address, 'result', [area])
  }

  async firstVisit(): Promise<void> {
    try {
      await this.$store.dispatch('anket/getGeoIp')

      if (this.$vuetify.breakpoint.mdAndUp) {
        this.isShowConfirmGeoIpDialog = true

        this.$nextTick(() => {
          this.updateGeoConfirmModalPositon()
        })
      }

      this.innerForm.area = this.area
    } catch (e: unknown) {
      console.error(e)
    }
  }

  onShowCitiesDialog(): void {
    this.isShowCitiesListDialog = true
    this.isShowConfirmGeoIpDialog = false
  }

  updateGeoConfirmModalPositon(): void {
    if (!this.$vuetify.breakpoint.mdAndUp) return

    const modal = document.querySelector<HTMLElement>('.geo-confirm-content')
    const cityField = document.querySelector<HTMLElement>(
      '.search-form-autocomplete'
    )

    if (!modal || !cityField) return

    const cityRect: DOMRect = cityField.getBoundingClientRect()
    const modalRect: DOMRect = modal.getBoundingClientRect()

    modal.style.top = `${cityRect.y - cityRect.height * 2}px`
    modal.style.left = `${
      cityRect.x + cityField.offsetWidth - modalRect.width
    }px`
  }

  async mounted(): Promise<void> {
    if (!this.innerForm.area?.code) {
      await this.firstVisit()
    }

    Vue.set(this.address, 'result', [this.area])
    window.addEventListener('resize', this.updateGeoConfirmModalPositon)
    eventBus.$on('submit-search-form', this.submitForm)
  }

  beforeDestroy(): void {
    eventBus.$off('submit-search-form', this.submitForm)
    window.removeEventListener('resize', this.updateGeoConfirmModalPositon)
  }
}
